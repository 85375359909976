import React from "react"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Markdown from "react-markdown/with-html"
import { FileEarmarkMedical } from "react-bootstrap-icons"
import Link from "@material-ui/core/Link"
import Tooltip from "@material-ui/core/Tooltip"
import { useLinksDictionary } from "../../dictionaries/linksDictionary"
import { useIntl } from "gatsby-plugin-react-intl"

const TermsOfUseBanner = ({ cms }) => {
  const { links } = useLinksDictionary()
  const intl = useIntl()

  return (
    <Box
      className="terms-of-use-banner"
      component="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <h2 class="terms-of-use-banner__title">{cms.title}</h2>
          <Tooltip
            title={intl.formatMessage({
              id: "file_pdfVersionLabel",
              defaultMessage: "Wersja pdf",
            })}
          >
            <Link
              className="terms-of-use-banner__pdf-link"
              target="_blank"
              rel="noreferrer"
              href={links.terms_of_use}
            >
              <FileEarmarkMedical></FileEarmarkMedical>
            </Link>
          </Tooltip>
          <p class="terms-of-use-banner__publication-date">
            {cms.publication_date}
          </p>
        </Box>

        <div class="terms-of-use-banner__content">
          <Markdown allowDangerousHtml>{cms.content}</Markdown>
        </div>
      </Container>
    </Box>
  )
}

export default TermsOfUseBanner
