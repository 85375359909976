import React from "react"
import { graphql } from "gatsby"
import TermsOfUseBanner from "../sections/regulations/terms-of-us-banner"
import Layout from "../components/layout"

const TermsOfUsePage = ({ data }) => {
  return (
    <Layout footerCms={data.strapiFooter}>
      <TermsOfUseBanner cms={data.strapiTermsOfUse}></TermsOfUseBanner>
    </Layout>
  )
}

export const query = graphql`
  query TermsOfUse($language: String) {
    strapiTermsOfUse(locale: { eq: $language }) {
      title
      publication_date
      content
    }
    strapiFooter(locale: { eq: $language }) {
      company_name
      company_address
      call_center
      contact
      data_administrator
      rights_reserved
      page_links {
        title
        link
      }
      document_links {
        title
        link
      }
    }
  }
`

export default TermsOfUsePage
